<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-6, .cls-7 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5,
      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#inox-gradient-2);
      }
    </svg:style>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 39.01"
                    :y1="doorTopHeight + 123.91"
                    :x2="doorLeftWidth1 + 102.64"
                    :y2="doorTopHeight + 123.91"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-2"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 39.01"
                    :y1="doorTopHeight + 70.88"
                    :x2="doorLeftWidth1 + 102.64"
                    :y2="doorTopHeight + 70.88"
                    xlink:href="#handle-gradient"/>

    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>

    <g id="H05">
      <rect id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-4"
            :x="`${doorLeftWidth1 + 39.01}`"
            :y="`${doorTopHeight + 113.3}`"
            width="63.63"
            height="21.21"/>

      <rect filter="url(#inset-shadow)"
            id="glass"
            class="cls-5"
            :x="`${doorLeftWidth1 + 43.25}`"
            :y="`${doorTopHeight + 117.54}`"
            width="55.14"
            height="12.73"/>

      <rect id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-6"
            :x="`${doorLeftWidth1 + 39.01}`"
            :y="`${doorTopHeight + 60.27}`"
            width="63.63"
            height="21.21"/>

      <rect filter="url(#inset-shadow)"
            id="glass-2"
            data-name="glass"
            class="cls-7"
            :x="`${doorLeftWidth1 + 43.25}`"
            :y="`${doorTopHeight + 64.51}`"
            width="55.14"
            height="12.73"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    }
  }
}
</script>
